<template>
  <img
    v-img-fallback="'largedark'"
    :src="displaySrc"
    class="cursor-pointer w-full h-full object-contain"
    :class="{'opacity-0' : isModalOpen }"
    @click.prevent="zoom"
  >
</template>
<script>
const ImageLightbox = () => import('@modals/ImageLightbox')

export default {
  props: ['asset'],

  data () {
    return {
      isModalOpen: false
    }
  },

  computed: {
    displaySrc () {
      return this.asset.variant_urls.images.medium
    }
  },

  methods: {
    zoom () {
      this.$modal.show(
        ImageLightbox, {
          src: this.asset.url
        }, {
          height: 'auto',
          width: '95%',
          scrollable: true
        },
        {
          'before-open': (event) => {
            this.isModalOpen = true
          },
          'before-close': (event) => {
            this.isModalOpen = false
          }
        }
      )
    }
  }
}

</script>
